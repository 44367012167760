<template>
    <div class="bg-[#F8FAFC]">
        <div class="max-w-7xl mx-auto md:p-14 px-4 py-14 text-[#232a34]">
            <h2 class="text-3xl font-bold" id="try-it-out">Try it out</h2>
            <h3 class="text-2xl font-medium">Interact with demo</h3>
            <div class="flex justify-between items-center flex-col md:flex-row  mt-4 my-8">
                <div class="flex gap-4 ">
                    <a href="https://drive.google.com/drive/folders/11ll7x0p8n68T0SWsk0cMbRqfVAL7xTMy" target="_blank"
                        class="flex items-center gap-4 p-4 bg-white rounded-lg border-2 border-white hover:border-primary-300 hover:underline transition">
                        <Icon name="logos:google-drive" class="w-10 h-10" />
                        <div class="flex flex-col gap-2">
                            <span class="text-primary-500">
                                Browse files
                            </span>
                        </div>
                    </a>
                </div>
                <div>

                    <a href="https://search-your-slide-decks.demo.pathway.com/#search-your-slide-decks" target="_blank"
                        class="bg-white block p-4  flex gap-4 items-center mt-4 border-2 hover:border-primary-800 hover:underline transition rounded-se-lg rounded-es-lg border-primary-500">
                        <div>
                            <img src="/assets/design/logo/logo-p.svg" alt="" class="w-10 h-10" />
                        </div>
                        <div>
                            <span class="font-semibold text-[#232a34]">Try out the demo</span>
                        </div>
                    </a>
                </div>
            </div>
            <div class="flex flex-col text-sm mt-4"><span><span class="font-semibold">DEMO: Uploaded files will be
                        visible to the public.</span></span><span>Data processing is subject to our <a
                        href="/privacy_gdpr_di#privacy-policy" class="text-primary-500 underline">privacy
                        policy</a></span></div>
        </div>
    </div>
</template>
