export default {
  "bootcamp-register": () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/bootcamp-register.vue").then(m => m.default || m),
  default: () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/default.vue").then(m => m.default || m),
  event: () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/event.vue").then(m => m.default || m),
  full: () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/full.vue").then(m => m.default || m),
  landing: () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/landing.vue").then(m => m.default || m),
  "page-card": () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/page-card.vue").then(m => m.default || m),
  "page-narrow": () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/page-narrow.vue").then(m => m.default || m),
  page: () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/page.vue").then(m => m.default || m),
  solutions: () => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/solutions.vue").then(m => m.default || m)
}