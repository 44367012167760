<template>
    <div class="bg-[#19242F]">
        <div class="max-w-7xl mx-auto md:p-8 md:py-16 md:pt-16 px-4 py-8 text-white">
            <span class="text-3xl font-semibold">Backed by Enterprise Security & Authentication</span>
            <div class="flex flex-col md:flex-row gap-8 justify-between items-center">
                <div class="md:w-[75%]">
                    <ul class="flex flex-col gap-4 mt-8">
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Host on your
                                cloud
                                or
                                on-premise</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Secure by
                                Design</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Granular
                                Access
                                Management</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" />
                            <span>Compliance-Ready</span>
                        </li>
                    </ul>
                </div>
                <div class="flex items-center justify-center h-full">
                    <Calendly name="GetStarted" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                        class="flex gap-2 items-center text-white font-bold whitespace-nowrap text-xl hover:!bg-transparent block w-full md:max-w-max !bg-primary-500 px-6 py-3 rounded-lg border-2 border-primary-500 !transition">
                        Let's talk
                        <Icon name="heroicons:chat-bubble-left-right-20-solid" />
                    </Calendly>
                </div>
            </div>

        </div>
    </div>
</template>
